import { Loader2 } from "lucide-react";
import { Suspense, ComponentType } from "react";

const SuspenseLoader = () => {
  return (
    <div className="w-full h-[80vh] flex items-center justify-center bg-transparent">
      <div>
        <Loader2 className="mr-2 size-6 animate-spin" />
      </div>
    </div>
  );
};

const Loader =
  <P extends object>(Component: ComponentType<P>): ComponentType<P> =>
  (props: P) =>
    (
      <Suspense fallback={<SuspenseLoader />}>
        <Component {...props} />
      </Suspense>
    );

export { Loader };
export default SuspenseLoader;
