import React from "react";

const Loading = () => {
  return (
    <div className="relative">
      <div className="loader-3" />
    </div>
  );
};

export default Loading;
