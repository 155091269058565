import React from "react";

const AuthLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <section>
      <div className="h-[72px] border-b border-slate-300 px-2">
        <h1 className="text-slate-700 text-xl font-semibold leading-[72px] cursor-pointer">
          VSA Trading
        </h1>
      </div>
      <div className="h-[calc(100vh-72px)] px-4 py-2 flex">{children}</div>
    </section>
  );
};

export default AuthLayout;
