import React, { ButtonHTMLAttributes } from "react";

import { CheckIcon } from "src/icons";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {}

const CheckButton = ({ ...props }: Props) => {
  return (
    <button
      {...props}
      className="rounded-full bg-green-500 p-[18px] disabled:bg-slate-300"
    >
      <CheckIcon />
    </button>
  );
};

export default CheckButton;
