import { toast } from "sonner";
import { twMerge } from "tailwind-merge";
import { clsx, type ClassValue } from "clsx";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const handleGlobalError = (err: unknown, teamId?: string) => {
  if (err && typeof err === "object" && "response" in err) {
    const response = (
      err as { response: { status: number; data: { detail?: string } } }
    ).response;

    if (response?.status === 401 || response?.status === 400) {
      toast.error(response?.data?.detail || "Unauthorized");
      return;
    }

    // if (response?.status === 406) {
    //   store.dispatch(
    //     setError({
    //       errorStatus: true,
    //       errorMessage:
    //         response?.data?.detail || "Session expired. Please log in again.",
    //       teamId,
    //     }),
    //   );
    //   return;
    // }
  }

  toast.error(
    "There seems to be an issue with getting responses. Apologize for the inconvenience, please try again.",
  );
};
