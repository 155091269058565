import React, { ButtonHTMLAttributes } from "react";

import { CloseIcon } from "src/icons";

const CloseButton = ({ ...props }: ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      {...props}
      className="rounded-full bg-red-500 p-[18px] disabled:bg-slate-300"
    >
      <CloseIcon />
    </button>
  );
};

export default CloseButton;
