import React from "react";

import { Route, Routes, BrowserRouter as UserRouter } from "react-router-dom";

import { NotFound } from "src/components/common";
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from "./router";
import {
  Toaster,
  Toaster as ToasterSonner,
} from "src/components/shadcn/Sonner";

import PrivateRoute from "src/layouts/components/PrivateRoute";

function App() {
  return (
    <React.Fragment>
      <UserRouter>
        <Routes>
          {PUBLIC_ROUTES.map((route) => {
            const Page = route.page;
            const Layout = route.layout;

            return (
              <Route
                key={route.id}
                path={route.path}
                element={
                  <Layout>
                    <Page />
                  </Layout>
                }
              />
            );
          })}
          {PRIVATE_ROUTES.map((route) => {
            const Page = route.page;
            const Layout = route.layout;

            return (
              <Route
                key={route.id}
                path={route.path}
                element={
                  <PrivateRoute>
                    <Layout>
                      <Page />
                    </Layout>
                  </PrivateRoute>
                }
              />
            );
          })}
          <Route
            path="*"
            element={<NotFound />}
          />
        </Routes>
      </UserRouter>

      <Toaster />
      <ToasterSonner richColors />
    </React.Fragment>
  );
}

export default App;
