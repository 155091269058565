export const MENUS = [
  {
    id: 1,
    title: "Play Charts",
    path: "/play-charts",
  },
  {
    id: 2,
    title: "Charts History",
    path: "/charts-history",
  },
  {
    id: 3,
    title: "Learn Concepts",
    path: "/learn-concepts",
  },
  {
    id: 4,
    title: "Quiz Test",
    path: "/quiz-test",
  },
];
